import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import "./LoginDropdown.css";

const LoginDropdown = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const clientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
  const logoutUri = process.env.REACT_APP_COGNITO_LOGOUT_REDIRECT_URI;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSignOut = async () => {
    try {
      console.log("Revoking session and signing out...");
      auth.removeUser();
      localStorage.clear();
      window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
    } catch (error) {
      console.error("Sign-out failed:", error);
    }
  };

  const handleLinkClick = (path) => {
    if (!auth.isAuthenticated) {
      auth.signinRedirect();
    } else {
      navigate(path);
    }
  };

  // Check if user is in the "admin" group
  const isAdmin = auth?.user?.profile?.["cognito:groups"]?.includes("admin");

  return (
    <div
      className="login-dropdown"
      onMouseEnter={toggleDropdown}
      onMouseLeave={toggleDropdown}
    >
      <div className="dropdown-header">
        <span>Hello, {auth?.user?.profile?.given_name || "sign in"}</span>
        <span className="dropdown-arrow">▾</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-actions">
            {auth.isAuthenticated ? (
              <button onClick={handleSignOut} className="dropdown-button">
                Sign out
              </button>
            ) : (
              <button onClick={() => auth.signinRedirect()} className="dropdown-button">
                Sign in
              </button>
            )}
          </div>
          <div className="dropdown-section">
            <h4>Your Account</h4>
            <ul>
              <li>
                <span onClick={() => handleLinkClick("/account")}>
                  Your Account
                </span>
              </li>
              <li>
                <span onClick={() => handleLinkClick("/orders")}>Your Orders</span>
              </li>
              {isAdmin && ( // Conditionally render "Admin" link
                <li>
                  <span onClick={() => handleLinkClick("/dashboard")}>Admin</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginDropdown;
