import React from 'react';
import './AccountOverview.css';

const AccountOverview = ({ user }) => {
  return (
    <div className="account-overview">
      <h2>Account Overview</h2>
      <p><strong>Name:</strong> {user.name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Member Since:</strong> {new Date(user.createdAt).toLocaleDateString()}</p>
    </div>
  );
};

export default AccountOverview;
