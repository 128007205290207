import React, { useState } from "react";
import Header from "../components/layout/header/Header";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../services/paymentService";
import "./GuestCheckoutPage.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const GuestCheckoutPage = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [stateField, setStateField] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  const handleProceedToPayment = async (event) => {
    event.preventDefault();

    // Basic form validation
    if (!email || !firstName || !addressLine1 || !city || !postalCode || !country) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      console.log("[INFO] Proceeding with guest checkout.");

      let guest_id = localStorage.getItem("guest_id");
      if (!guest_id) {
        guest_id = `guest_${crypto.randomUUID()}`;
        localStorage.setItem("guest_id", guest_id);
      }

      const localCart = JSON.parse(localStorage.getItem("cart")) || [];
      if (!localCart || localCart.length === 0) {
        alert("Your cart is empty.");
        console.error("Guest cart is empty.");
        return;
      }

      const payload = {
        guest_id,
        email,
        first_name: firstName,
        last_name: lastName,
        address_line1: addressLine1,
        address_line2: addressLine2,
        city,
        state: stateField,
        postal_code: postalCode,
        country,
        cart_items: localCart.map((item) => ({
          product_id: item.id,
          quantity: item.quantity,
        })),
        metadata: {
          guest_id,
          shipping_address: `${addressLine1}, ${addressLine2 || ""}, ${city}, ${stateField || ""}, ${postalCode}, ${country}`,
        },
      };

      // ========================
      // CREATE CHECKOUT SESSION
      // ========================
      const stripe = await stripePromise;
      console.log("[INFO] Checkout session payload:", payload);

      const data = await createCheckoutSession(payload);

      if (data && data.sessionId) {
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      } else {
        console.error("[ERROR] No sessionId returned from server:", data);
        alert("Error creating checkout session. Please try again.");
      }
    } catch (err) {
      console.error("[ERROR] Error creating checkout session:", err);
      alert("An error occurred while processing your checkout. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <div className="guest-checkout-page">
        <h1>Guest Checkout</h1>
        <p className="subtitle">Please enter your shipping and contact details:</p>

        <form className="guest-checkout-form" onSubmit={handleProceedToPayment}>
          <div className="form-group">
            <label htmlFor="firstName">First Name *</label>
            <input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="form-group full-width">
            <label htmlFor="email">Email *</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group full-width">
            <label htmlFor="addressLine1">Address Line 1 *</label>
            <input
              id="addressLine1"
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              required
            />
          </div>

          <div className="form-group full-width">
            <label htmlFor="addressLine2">Address Line 2</label>
            <input
              id="addressLine2"
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City *</label>
            <input
              id="city"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="state">State</label>
            <input
              id="state"
              type="text"
              value={stateField}
              onChange={(e) => setStateField(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="postalCode">Postal Code *</label>
            <input
              id="postalCode"
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="country">Country *</label>
            <input
              id="country"
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </div>

          <button className="btn proceed-button" type="submit">
            Proceed to Payment
          </button>
        </form>
      </div>
    </>
  );
};

export default GuestCheckoutPage;
