import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to get the token from localStorage
const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const token = getAuthToken(); // Fetch token
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error during request to ${endpoint}:`, error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    }
    throw error; // Rethrow error for handling in the caller
  }
};

// Updated methods for User Addresses (Address Book)

// Add a new user address
export const addUserAddress = async (userId, addressData) => {
  const data = { user_id: userId, address_data: addressData };
  return await makeRequest("post", "/shipping/user-address", data);
};

// Get all user addresses for a specific user
export const getUserAddresses = async (userId) => {
  return await makeRequest("get", `/shipping/user-address/user/${userId}`);
};

// Update an existing user address
export const updateUserAddress = async (addressId, updatedData) => {
  return await makeRequest("put", `/shipping/user-address/${addressId}`, updatedData);
};

// Delete a user address by ID
export const deleteUserAddress = async (addressId) => {
  return await makeRequest("delete", `/shipping/user-address/${addressId}`);
};

// Fetch user details
export const fetchUserDetails = async () => {
  return await makeRequest("get", "/user/details");
};

// Update user details
export const updateUserDetails = async (updatedData) => {
  return await makeRequest("put", "/user/details", updatedData);
};

// Fetch order history
export const getOrderHistory = async (userId) => {
  return await makeRequest("get", `/orders/user/${userId}`);
};

// Reset user password
export const resetPassword = async (email) => {
  return await makeRequest("post", "/user/reset-password", { email });
};

// Change user password
export const changePassword = async (currentPassword, newPassword) => {
  return await makeRequest("post", "/user/change-password", {
    current_password: currentPassword,
    new_password: newPassword,
  });
};

export default {
  addUserAddress,
  getUserAddresses,
  updateUserAddress,
  deleteUserAddress,
  fetchUserDetails,
  updateUserDetails,
  getOrderHistory,
  resetPassword,
  changePassword,
};
