import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const RoleBasedRedirect = () => {
  const { isAuthenticated, user, loading } = useAuth();
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (!loading && isAuthenticated && user && !redirected) {
      // Redirect logic based on user's groups
      const groups = user?.groups || [];
      let redirectPath = "/";

      if (groups.includes("user")) {
        redirectPath = "/account";
      }

      console.log("Redirecting user to:", redirectPath);

      if (window.location.pathname === "/") {
        navigate(redirectPath, { replace: true });
        setRedirected(true); // Avoid further redirection
      }
    }
  }, [isAuthenticated, user, loading, redirected, navigate]);

  return null; // Nothing to render
};

export default RoleBasedRedirect;
