import React, { useState, useEffect } from "react";
import DashboardCard from "../components/common/DashboardCard";
import AddAddressModal from "./AddAddressModal";
import Header from "../components/layout/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons"; // Import trash icon
import {
  getUserAddresses,
  addUserAddress,
  deleteUserAddress,
} from "../services/UserService";
import { useAuth } from "../context/AuthContext";
import "./AddressBook.css";

const AddressBook = () => {
  const { user } = useAuth();
  const userId = user?.sub;

  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        if (!userId) {
          console.error("No user ID provided");
          return;
        }
        setLoading(true);

        const response = await getUserAddresses(userId);

        if (response.success && Array.isArray(response.addresses)) {
          console.log("Fetched addresses:", response.addresses);
          setAddresses(response.addresses);
        } else {
          console.error("Failed to fetch addresses or no addresses found.");
          setAddresses([]);
        }
      } catch (err) {
        console.error("Failed to fetch addresses:", err);
        setAddresses([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, [userId]);

  const handleAddAddress = async (newAddress) => {
    try {
      if (!userId) {
        console.error("No user ID provided for adding address.");
        return;
      }

      const response = await addUserAddress(userId, newAddress);

      if (response.success) {
        const savedAddress = {
          id: response.user_address_id, // The backend returns `user_address_id` for the new address
          ...newAddress,
        };

        setAddresses((prevAddresses) => [...prevAddresses, savedAddress]);
        setShowModal(false);
      } else {
        console.error("Failed to add address:", response.error);
      }
    } catch (err) {
      console.error("Failed to add address:", err);
    }
  };

  const handleRemove = async (addressId) => {
    if (!addressId) {
      console.error("No address ID provided for removal.");
      return;
    }
    try {
      const response = await deleteUserAddress(addressId);
      if (response.success) {
        setAddresses((prevAddresses) =>
          prevAddresses.filter((address) => address.id !== addressId)
        );
      } else {
        console.error("Failed to delete address:", response.error);
      }
    } catch (err) {
      console.error("Failed to delete address:", err);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div>
      <div className="header-container">
        <Header />
      </div>
      <div className="address-book">
        <h2>Address Book</h2>
        <p className="subtitle">Manage your saved addresses for faster checkout.</p>
        {loading && <div className="spinner"></div>}
        {!loading && !addresses.length && (
          <DashboardCard
            title={
              <>
                <span className="add-address-icon">➕</span> Add a New Address
              </>
            }
            description="Click here to add your shipping address."
            link="#"
            onClick={toggleModal}
          />
        )}
        {!loading && addresses.length > 0 && (
          <div className="address-grid">
            {addresses.map((address) => (
              <DashboardCard
                key={address.id}
                title={
                  <div className="address-title">
                    {address.address_line1}
                    <button
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(address.id);
                      }}
                      style={{ padding: "5px", marginLeft: "10px" }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                }
                description={`${address.city}, ${address.state || ''} ${address.postal_code}, ${address.country}`}
                link="#"
              />
            ))}
            <DashboardCard
              title={
                <>
                  <span className="add-address-icon">➕</span> Add a New Address
                </>
              }
              description="Click here to add your shipping address."
              link="#"
              onClick={toggleModal}
            />
          </div>
        )}

        <AddAddressModal
          isOpen={showModal}
          onClose={toggleModal}
          onSave={handleAddAddress}
        />
      </div>
    </div>
  );
};

export default AddressBook;
