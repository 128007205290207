import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const ProtectedRoute = ({ children, requiredGroups = [] }) => {
  const auth = useAuth();
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 5; // Maximum retry attempts
  const retryDelay = 1000; // Delay between retries in milliseconds

  useEffect(() => {
    const retryAuthentication = async () => {
      if (retryCount < maxRetries && !auth.isAuthenticated && !auth.loading) {
        console.log(`Retrying authentication (${retryCount + 1}/${maxRetries})...`);
        setRetryCount((prev) => prev + 1);

        // Assuming `fetchUser` or a similar function exists in your AuthContext
        if (auth.fetchUser) {
          await auth.fetchUser();
        }
      }
    };

    if (!auth.isAuthenticated && retryCount < maxRetries) {
      const retryTimeout = setTimeout(retryAuthentication, retryDelay);

      return () => clearTimeout(retryTimeout); // Clean up timeout
    }
  }, [auth.isAuthenticated, auth.loading, retryCount, auth]);

  if (auth.loading) {
    console.log("Authentication state is still loading...");
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    console.log("User is not authenticated. Redirecting to home...");
    return <Navigate to="/" replace />;
  }

  // Check if user belongs to any of the required groups
  if (
    requiredGroups.length > 0 &&
    !requiredGroups.some((group) => auth.hasRole(group))
  ) {
    console.log(
      `User does not belong to any of the required groups (${requiredGroups.join(", ")}). Redirecting to home...`
    );
    return <Navigate to="/" replace />;
  }

  console.log("User is authenticated and authorized. Rendering children...");
  return children;
};

export default ProtectedRoute;
