import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null, requiresAuth = true) => {
  try {
    const headers = {};
    if (requiresAuth) {
      const token = localStorage.getItem('authToken');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      } else {
        console.warn("Auth token is missing for a protected request.");
      }
    }

    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error during request to ${endpoint}:`, error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    }
    throw error;
  }
};

// Fetch order details including receipt URL
export const fetchOrderConfirmation = async (sessionId) => {
  const params = { session_id: sessionId };
  return await makeRequest('get', '/order/order-confirmation', null, params, false); // No token required
};

// Example private function requiring authentication
export const fetchProtectedData = async () => {
  return await makeRequest('get', '/protected/data', null, null, true); // Token required
};
