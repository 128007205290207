import React, { useState, useEffect } from "react";
import Header from "../components/layout/header/Header";
import { getUserAddresses, addUserAddress } from "../services/UserService";
import { useAuth } from "../context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../services/paymentService";
import AddAddressModal from "./AddAddressModal";
import "./AuthenticatedCheckoutPage.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AuthenticatedCheckoutPage = () => {
  const { user } = useAuth();
  const userId = user?.sub; // Ensure the user ID is extracted correctly
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (!userId) {
        console.error("No user ID found. Redirecting to Guest Checkout...");
        return;
      }
      try {
        setLoading(true);
        const response = await getUserAddresses(userId);
        if (response.success && Array.isArray(response.addresses)) {
          setAddresses(response.addresses);
        } else {
          console.error("Failed to fetch addresses or no addresses found.");
          setAddresses([]);
        }
      } catch (err) {
        console.error("Failed to fetch addresses:", err);
        setAddresses([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, [userId]);

  const handleAddressSelection = (id) => setSelectedAddressId(id);

  const handleProceedToPayment = async () => {
  if (!selectedAddressId) {
    alert("Please select a shipping address before proceeding.");
    return;
  }

  if (!userId) {
    console.error("User ID is missing. Cannot proceed to payment.");
    return;
  }

  // Fetch the full details of the selected address
  const selectedAddress = addresses.find((addr) => addr.id === selectedAddressId);

  if (!selectedAddress) {
    console.error("Selected address not found in address list.");
    return;
  }

  try {
    const stripe = await stripePromise;

    const payload = {
      user_id: userId,
      user_address_id: selectedAddressId,
      metadata: {
        user_id: userId,
        user_address_id: selectedAddressId,
        shipping_address: `${selectedAddress.address_line1}, ${
          selectedAddress.address_line2 || ""
        }, ${selectedAddress.city}, ${
          selectedAddress.state || ""
        }, ${selectedAddress.postal_code}, ${selectedAddress.country}`,
      },
    };

    const data = await createCheckoutSession(payload);

    if (data.sessionId) {
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    } else {
      console.error("No sessionId returned from server:", data);
    }
  } catch (error) {
    console.error("Error creating checkout session:", error);
  }
};


  return (
    <>
      <Header />
      <div className="authenticated-checkout-page">
        <h1>Select a Shipping Address</h1>
        {loading && <div className="spinner"></div>}

        {!loading && addresses.length === 0 && (
          <div className="no-address-message">
            <p>You have no saved addresses. Please add one before proceeding.</p>
          </div>
        )}

        {!loading && addresses.length > 0 && (
          <div className="address-list">
            {addresses.map((address) => (
              <label key={address.id} className="address-option">
                <input
                  type="radio"
                  name="selectedAddress"
                  value={address.id}
                  onChange={() => handleAddressSelection(address.id)}
                  checked={selectedAddressId === address.id}
                />
                <span className="address-details">
                  {address.address_line1}, {address.city}, {address.state || ''} {address.postal_code}, {address.country}
                </span>
              </label>
            ))}
          </div>
        )}

        <button
          className="btn btn-primary proceed-button"
          onClick={handleProceedToPayment}
          disabled={!selectedAddressId}
        >
          Proceed to Payment
        </button>

        <div style={{ marginTop: "20px" }}>
          <button className="btn btn-secondary" onClick={() => setIsModalOpen(true)}>
            Add New Address
          </button>
        </div>

        <AddAddressModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={async (newAddress) => {
            if (!userId) return;
            try {
              const response = await addUserAddress(userId, newAddress);
              if (response.success) {
                setAddresses((prev) => [...prev, { id: response.user_address_id, ...newAddress }]);
              } else {
                console.error("Failed to add address:", response.error);
              }
            } catch (err) {
              console.error("Failed to add address:", err);
            }
          }}
        />
      </div>
    </>
  );
};

export default AuthenticatedCheckoutPage;
