import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Input, notification } from 'antd';
import { SearchOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  fetchProducts,
  fetchProductDetails,
  addOrUpdateProduct,
  fetchCategories,
  deleteImage,
  fetchProductTypeSchema,
} from '../../services/productService';
import ProductModal from './ProductModal';
import createSocket from '../../services/socket'; // Import the factory function
import Header from '../layout/header/Header'; // Import Header component
import './ProductManagement.css';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productTypeSchemas, setProductTypeSchemas] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const adminLinks = [
    { label: 'Admin Home', path: '/dashboard' },
    { label: 'Settings', path: '/admin/settings' },
  ];

  useEffect(() => {
    loadProducts(pagination.current, pagination.pageSize, searchTerm);
    loadCategories();
    loadProductTypeSchemas();

    // Initialize socket connection for /products namespace
    const productSocket = createSocket('/products');

    // Listen for product updates
    productSocket.on('product_update', handleProductUpdate);

    // Cleanup
    return () => {
      productSocket.off('product_update', handleProductUpdate);
      productSocket.disconnect();
    };
  }, []);

  const loadProducts = async (page = 1, pageSize = 10, search = '') => {
    try {
      setLoading(true);
      const data = await fetchProducts({ page, pageSize, search });

      if (data.products && data.products.length > 0) {
        setProducts(data.products);
        setPagination({
          current: data.page,
          pageSize: data.page_size,
          total: data.total_items,
        });
      } else {
        setProducts([]);
        notification.warning({
          message: 'No Products Found',
          description: 'No products match your search criteria.',
        });
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadCategories = async () => {
    try {
      const categories = await fetchCategories();
      setCategoryOptions(categories.map((cat) => ({ value: cat.id, label: cat.name })));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const loadProductTypeSchemas = async () => {
    try {
      const schemas = await fetchProductTypeSchema();
      setProductTypeSchemas(schemas);
    } catch (error) {
      console.error('Error fetching product type schemas:', error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    loadProducts(1, pagination.pageSize, value);
  };

  const handleTableChange = (pagination) => {
    loadProducts(pagination.current, pagination.pageSize, searchTerm);
  };

  const showModal = async (product = null) => {
    if (product) {
      try {
        setModalLoading(true);
        const productData = await fetchProductDetails(product.id);

        if (productData.product_type && productTypeSchemas.length > 0) {
          const schema = productTypeSchemas.find(
            (typeSchema) => typeSchema.name === productData.product_type
          );

          if (schema) {
            productData.attributes = schema.attributes.reduce((acc, attr) => {
              acc[attr.attribute_name] = productData.attributes?.[attr.attribute_name] || '';
              return acc;
            }, {});
          }
        }

        setEditingProduct({ ...productData });
        setIsModalVisible(true);
      } catch (error) {
        console.error('Error fetching product details for edit:', error);
      } finally {
        setModalLoading(false);
      }
    } else {
      setEditingProduct(null);
      setIsModalVisible(true);
    }
  };

  const handleModalSubmit = async (values, files) => {
    try {
      setModalLoading(true);

      // Save product details
      const response = editingProduct
        ? await addOrUpdateProduct({ ...values, product_id: editingProduct.id }, files)
        : await addOrUpdateProduct(values, files);

      if (!response || response.error) {
        throw new Error(response.error || 'Failed to save product.');
      }

      notification.success({
        message: 'Success',
        description: editingProduct
          ? 'Product updated successfully!'
          : 'Product added successfully!',
      });

      setIsModalVisible(false);
      loadProducts(pagination.current, pagination.pageSize, searchTerm);
    } catch (error) {
      console.error('Error updating/adding product:', error);
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to add/update product. Please try again.',
      });
    } finally {
      setModalLoading(false);
    }
  };

  const handleProductUpdate = useCallback((data) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => (product.id === data.id ? { ...product, ...data } : product))
    );
  }, []);

  const onDeleteImage = async (imageId) => {
    try {
      await deleteImage(imageId);
      notification.success({ message: 'Image deleted successfully' });
      if (editingProduct) {
        setEditingProduct((prevProduct) => ({
          ...prevProduct,
          assets: prevProduct.assets.filter((asset) => asset.id !== imageId),
        }));
      }
    } catch (error) {
      notification.error({ message: 'Failed to delete image', description: error.message });
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories) => categories.map((cat) => cat.name).join(', '),
    },
    {
      title: 'Price (ARS)',
      dataIndex: 'adjusted_selling_price_ars',
      key: 'adjusted_selling_price_ars',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button icon={<EditOutlined />} onClick={() => showModal(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="product-management">
      <Header showSearchBar={false} showCartIcon={false} customLinks={adminLinks} />
      <div className="header">
        <Input
          placeholder="Search by ID, name, or description"
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: 300, marginRight: 10 }}
        />
        <Button icon={<PlusOutlined />} type="primary" onClick={() => showModal()}>
          Add Product
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={products}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={handleTableChange}
      />

      <ProductModal
        visible={isModalVisible}
        onOk={handleModalSubmit}
        onCancel={() => setIsModalVisible(false)}
        initialValues={editingProduct}
        isEditing={!!editingProduct}
        loading={modalLoading}
        categoryOptions={categoryOptions}
        onDeleteImage={onDeleteImage}
      />
    </div>
  );
};

export default ProductManagement;
