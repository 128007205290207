import React, { useState, useEffect } from "react";
import DashboardCard from "../components/common/DashboardCard";
import Header from "../components/layout/header/Header"; // Import Header
import "./AccountPage.css";

const AccountPage = () => {
  const [user, setUser] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchUserDetails();
    fetchUserAddresses();
    fetchUserOrders();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch("/api/user/details");
      const userData = await response.json();
      setUser(userData);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  const fetchUserAddresses = async () => {
    try {
      const response = await fetch("/api/user/addresses");
      const addressesData = await response.json();
      setAddresses(addressesData);
    } catch (error) {
      console.error("Failed to fetch user addresses:", error);
    }
  };

  const fetchUserOrders = async () => {
    try {
      const response = await fetch("/api/user/orders");
      const ordersData = await response.json();
      setOrders(ordersData);
    } catch (error) {
      console.error("Failed to fetch user orders:", error);
    }
  };

  const handleSaveSettings = async (updatedData) => {
    try {
      const response = await fetch("/api/user/settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
      if (response.ok) {
        console.log("Settings saved successfully");
        fetchUserDetails();
      } else {
        console.error("Failed to save settings");
      }
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const cards = [
    {
      title: "Account Overview",
      description: "View your account details.",
      link: "/account/overview",
    },
    {
      title: "Address Book",
      description: "Manage your saved addresses.",
      link: "/address-book",
    },
    {
      title: "Order History",
      description: "View your past orders.",
      link: "/order-history",
    },
    {
      title: "Account Settings",
      description: "Update your email or password.",
      link: "/account-settings",
    },
  ];

  return (
    <div>
      <div className="header-container">
        <Header />
      </div>
      <div className="account-page">
        <div className="account-content">
          <h1>My Account</h1>
          <div className="account-grid">
            {cards.map((card, index) => (
              <DashboardCard
                key={index}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
