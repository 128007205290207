import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, message } from 'antd';
import { fetchProductPriceHistory } from '../../services/pricingService'; // Import the new function
import { syncProductToStripe } from '../../services/productService'; // Ensure correct path

const PriceHistoryModal = ({ visible, onCancel, productId }) => {
  const [priceHistory, setPriceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    if (visible) {
      loadPriceHistory();
    }
  }, [visible]);

  const loadPriceHistory = async () => {
    try {
      setLoading(true);
      const data = await fetchProductPriceHistory(productId);
      setPriceHistory(data.history); // Ensure the backend returns `history` as an array
    } catch (error) {
      console.error('Error fetching price history:', error);
      message.error('Failed to fetch price history.');
    } finally {
      setLoading(false);
    }
  };

  const handleSyncToStripe = async () => {
    setSyncing(true);
    try {
      const response = await syncProductToStripe(productId);
      if (response.success) {
        message.success(`Product synced with Stripe! Stripe Product ID: ${response.stripe_product_id}`);
      } else {
        message.error(`Failed to sync product: ${response.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error syncing product with Stripe:', error);
      message.error('Error syncing product with Stripe.');
    } finally {
      setSyncing(false);
    }
  };

  const columns = [
    { title: 'Date', dataIndex: 'calculated_at', key: 'calculated_at' },
    { title: 'Adjusted Price (ARS)', dataIndex: 'adjusted_selling_price_ars', key: 'adjusted_selling_price_ars' },
    { title: 'Discount (%)', dataIndex: 'discount_percentage', key: 'discount_percentage' },
    { title: 'ROI (%)', dataIndex: 'roi_percentage', key: 'roi_percentage' },
  ];

  return (
    <Modal
      title={`Price History for Product ${productId}`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="sync" type="primary" loading={syncing} onClick={handleSyncToStripe}>
          Sync to Stripe
        </Button>,
      ]}
      width={800}
    >
      <Table
        columns={columns}
        dataSource={priceHistory}
        rowKey="calculated_at"
        loading={loading}
      />
    </Modal>
  );
};

export default PriceHistoryModal;
