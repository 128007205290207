import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { syncCart, fetchCart } from '../services/cartService';
import { useAuth } from './AuthContext';

const CartContext = createContext();

const initialState = {
    items: [],
    totalItems: 0,
    totalCost: 0,
};

const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ITEM': {
            const existingItem = state.items.find((item) => item.id === action.payload.id);

            let updatedItems;
            if (existingItem) {
                updatedItems = state.items.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, quantity: item.quantity + action.payload.quantity }
                        : item
                );
            } else {
                updatedItems = [...state.items, action.payload];
            }

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'REMOVE_ITEM': {
            const updatedItems = state.items.filter((item) => item.id !== action.payload.id);

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'UPDATE_QUANTITY': {
            const updatedItems = state.items.map((item) =>
                item.id === action.payload.id
                    ? { ...item, quantity: action.payload.quantity }
                    : item
            );

            const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = updatedItems.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items: updatedItems, totalItems, totalCost };
        }

        case 'SET_CART': {
            const items = action.payload.items || [];
            const totalItems = items.reduce((sum, item) => sum + item.quantity, 0);
            const totalCost = items.reduce((sum, item) => sum + item.quantity * item.price, 0);

            return { ...state, items, totalItems, totalCost };
        }

        case 'CLEAR_CART':
            return initialState;

        default:
            return state;
    }
};

export const CartProvider = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const [cart, dispatch] = useReducer(cartReducer, initialState);

    const saveCartToLocalStorage = (items) => {
        console.log("Saving cart to local storage:", items);
        localStorage.setItem('cart', JSON.stringify(items));
    };

    useEffect(() => {
        const initializeCart = async () => {
            try {
                if (isAuthenticated) {
                    const { items } = await fetchCart();
                    console.log("Fetched cart from backend:", items);
                    dispatch({ type: 'SET_CART', payload: { items } });
                } else {
                    const localCart = JSON.parse(localStorage.getItem('cart') || '[]');
                    console.log("Fetched cart from local storage:", localCart);
                    dispatch({ type: 'SET_CART', payload: { items: localCart } });
                }
            } catch (error) {
                console.error('Failed to initialize cart:', error);
            }
        };

        initializeCart();
    }, [isAuthenticated]);

    const syncCartForAuthenticatedUser = async () => {
        if (isAuthenticated) {
            try {
                console.log("Syncing cart with backend:", cart.items);
                const response = await syncCart(cart.items);
                console.log("Sync response from backend:", response);
            } catch (error) {
                console.error('Failed to synchronize authenticated user cart:', error);
            }
        }
    };

    const dispatchWithSync = async (action) => {
    // Get the updated state after applying the action
    const updatedCart = cartReducer(cart, action);
    const updatedItems = updatedCart.items;

    // Immediately dispatch the action
    dispatch(action);

    console.log("Updated cart items to be synced:", updatedItems);

    // Save guest cart to localStorage
    saveCartToLocalStorage(updatedItems);

    // Sync for authenticated users only
    if (isAuthenticated) {
        try {
            console.log("Syncing cart with backend:", updatedItems);
            const response = await syncCart(updatedItems);
            console.log("Sync response from backend:", response);
        } catch (error) {
            console.error("Failed to synchronize authenticated user cart:", error);
        }
    }
};

    const addToCart = async (productId, quantity) => {
        await dispatchWithSync({ type: 'ADD_ITEM', payload: { id: productId, quantity } });
    };

    const removeFromCart = async (productId) => {
        await dispatchWithSync({ type: 'REMOVE_ITEM', payload: { id: productId } });
    };

    const updateCartQuantity = async (productId, quantity) => {
        await dispatchWithSync({ type: 'UPDATE_QUANTITY', payload: { id: productId, quantity } });
    };

    const refreshCart = async () => {
        try {
            const { items } = await fetchCart();
            dispatch({ type: 'SET_CART', payload: { items } });
        } catch (error) {
            console.error('Failed to refresh cart:', error);
        }
    };

    const clearCartAfterPayment = () => {
        dispatch({ type: 'CLEAR_CART' });
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                dispatch: dispatchWithSync,
                addToCart,
                removeFromCart,
                updateCartQuantity,
                refreshCart,
                clearCartAfterPayment,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const {
        cart,
        dispatch,
        addToCart,
        removeFromCart,
        updateCartQuantity,
        refreshCart,
        clearCartAfterPayment,
    } = useContext(CartContext);

    return {
        cart,
        dispatch,
        addToCart,
        removeFromCart,
        updateCartQuantity,
        refreshCart,
        clearCartAfterPayment,
    };
};
