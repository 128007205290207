import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Context Providers
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";

// Pages and Components
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import Dashboard from "./components/admin/Dashboard";
import ProductManagement from "./components/admin/ProductManagement";
import PriceDynamics from "./components/admin/PriceDynamics";
import ProductDetail from "./components/product/ProductDetail";
import ProtectedRoute from "./components/protect/ProtectedRoute";
import CookieBanner from "./components/common/CookieBanner";
import CartPage from "./pages/CartPage";
import WelcomePage from "./pages/WelcomePage";
import RoleBasedRedirect from "./RoleBasedRedirect";
import AccountPage from "./pages/AccountPage";
import AccountOverview from "./pages/AccountOverview";
import AddressBook from "./pages/AddressBook";
import { useAuth } from "./context/AuthContext";
import AuthenticatedCheckoutPage from "./pages/AuthenticatedCheckoutPage";
import GuestCheckoutPage from "./pages/GuestCheckoutPage";
import OrderConfirmation from "./pages/OrderConfirmation"; // Import the component

function AppContent() {
  const { isAuthenticated } = useAuth();

  return (
    <main>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} /> {/* New Route */}

        {/* Checkout Routing */}
        <Route
          path="/checkout"
          element={isAuthenticated ? <AuthenticatedCheckoutPage /> : <GuestCheckoutPage />}
        />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute requiredGroups={["admin"]}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/product-management"
          element={
            <ProtectedRoute requiredGroups={["admin"]}>
              <ProductManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/pricing-dynamics"
          element={
            <ProtectedRoute requiredGroups={["admin"]}>
              <PriceDynamics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account-overview"
          element={
            <ProtectedRoute requiredGroups={["user", "admin"]}>
              <AccountOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute requiredGroups={["user", "admin"]}>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/address-book"
          element={
            <ProtectedRoute requiredGroups={["user", "admin"]}>
              <AddressBook />
            </ProtectedRoute>
          }
        />

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </main>
  );
}

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <div className="App">
            <CookieBanner />
            <RoleBasedRedirect />
            <AppContent />
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
