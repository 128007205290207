import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to check if user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.log("[INFO] Guest user detected: no auth token found.");
    return false;
  }
  console.log("[INFO] Authenticated user detected: auth token found.");
  return true;
};

// Utility to get a valid auth token
const getAuthToken = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.warn("[WARN] No auth token found.");
    throw new Error("User is not authenticated. Please log in.");
  }
  return token;
};

// Create an Axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    if (isAuthenticated()) {
      try {
        const token = await getAuthToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          console.log("[INFO] Attached auth token to request.");
        }
      } catch (error) {
        console.error("[ERROR] Failed to attach auth token:", error);
      }
    } else {
      console.log("[INFO] Skipping auth token for guest user.");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Fetches the current cart from the backend for logged-in users.
 * If the user is not authenticated, return an empty cart instead of calling the backend.
 */
export const fetchCart = async () => {
  if (!isAuthenticated()) {
    console.warn("[WARN] fetchCart called for a guest user. Returning an empty cart.");
    return { success: false, items: [] }; // Gracefully handle guest users
  }

  try {
    console.log("[INFO] Fetching cart for authenticated user.");
    const response = await apiClient.get('/cart');
    if (response.data && response.data.items) {
      console.log("[INFO] Successfully fetched cart:", response.data);
      return response.data;
    }
    console.warn("[WARN] Unexpected cart structure:", response.data);
    return { success: false, items: [] };
  } catch (error) {
    console.error("[ERROR] Failed to fetch cart:", error);
    if (error.response?.status === 401) {
      console.warn("[WARN] Unauthorized access: removing invalid token.");
      localStorage.removeItem('authToken');
    }
    return { success: false, items: [] };
  }
};

/**
 * Synchronizes the guest cart with the backend.
 * If the user is not authenticated, log a warning and do nothing.
 */
export const syncGuestCart = async (guestId, items = []) => {
  if (isAuthenticated()) {
    console.warn("[WARN] syncGuestCart called for authenticated user. Ignoring.");
    return { success: false, items: [] };
  }

  try {
    console.log("[INFO] Syncing guest cart with backend.");
    const response = await apiClient.post('/cart/sync-guest', { guest_id: guestId, items });
    if (response.data && response.data.items) {
      console.log("[INFO] Successfully synced guest cart:", response.data.items);
      return { success: true, items: response.data.items };
    }
    console.warn("[WARN] Unexpected sync-guest response structure:", response.data);
    return { success: false, items: [] };
  } catch (error) {
    console.error("[ERROR] Failed to synchronize guest cart:", error);
    return { success: false, items: [] };
  }
};

/**
 * Synchronizes the logged-in user's cart with the backend.
 * If the user is not authenticated, return an empty response.
 */
export const syncCart = async (items = []) => {
  if (!isAuthenticated()) {
    console.warn("[WARN] syncCart called for guest user. Returning an empty response.");
    return { success: false, items: [] };
  }

  try {
    const formattedItems = items.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
    }));

    console.log("[INFO] Syncing cart with backend (payload):", formattedItems);

    const response = await apiClient.post('/cart/sync', { items: formattedItems });
    if (response.data && response.data.items) {
      console.log("[INFO] Successfully synced cart:", response.data.items);
      return { success: true, items: response.data.items };
    }

    console.warn("[WARN] Unexpected sync response structure:", response.data);
    return { success: false, items: [] };
  } catch (error) {
    console.error("[ERROR] Failed to synchronize cart:", error);
    return { success: false, items: [] };
  }
};

/**
 * Adds an item to the cart.
 */
export const addItemToCart = async (productId, quantity = 1) => {
  try {
    console.log("[INFO] Adding item to cart (productId:", productId, ", quantity:", quantity, ").");
    const response = await apiClient.post('/cart/add', { product_id: productId, quantity });
    return response.data;
  } catch (error) {
    console.error(`[ERROR] Failed to add item (productId: ${productId}) to cart:`, error);
    throw error;
  }
};

/**
 * Removes an item from the cart.
 */
export const removeItemFromCart = async (productId) => {
  try {
    console.log("[INFO] Removing item from cart (productId:", productId, ").");
    const response = await apiClient.delete(`/cart/remove/${productId}`);
    return response.data;
  } catch (error) {
    console.error(`[ERROR] Failed to remove item (productId: ${productId}) from cart:`, error);
    throw error;
  }
};

/**
 * Updates the quantity of a cart item.
 */
export const updateItemQuantity = async (productId, quantity) => {
  try {
    console.log("[INFO] Updating quantity for cart item (productId:", productId, ", quantity:", quantity, ").");
    const response = await apiClient.put(`/cart/update/${productId}`, { quantity });
    return response.data;
  } catch (error) {
    console.error(`[ERROR] Failed to update quantity for item (productId: ${productId}):`, error);
    throw error;
  }
};

/**
 * Clears the cart for the current user or session.
 */
