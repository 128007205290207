import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchOrderConfirmation } from "../services/orderService";
import Header from "../components/layout/header/Header";
import "./OrderConfirmation.css";

const OrderConfirmation = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get("session_id");

        if (!sessionId) {
          setError("Invalid session. Please check your link.");
          setLoading(false);
          return;
        }

        const response = await fetchOrderConfirmation(sessionId);
        setOrderDetails(response);
      } catch (err) {
        setError(err.response?.data?.error || "Failed to fetch order details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [location]);

  if (loading) {
    return (
      <div className="order-confirmation-loading">
        <div className="spinner"></div>
        <p>Loading your order details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="order-confirmation-error">
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="order-confirmation">
        <div className="confirmation-header">
          <span className="checkmark">✔</span>
          <h1>Order Placed Successfully!</h1>
          <p>Thank you for shopping with us. Your order is being processed.</p>
        </div>

        <div className="order-summary">
          <h2>Order Summary</h2>
          <p><strong>Amount Paid:</strong> ${orderDetails.amount_paid.toFixed(2)}</p>
        </div>

        {orderDetails.receipt_url && (
          <div className="receipt-section">
            <h2>Receipt</h2>
            <a
              href={orderDetails.receipt_url}
              target="_blank"
              rel="noopener noreferrer"
              className="receipt-button"
            >
              View Receipt
            </a>
          </div>
        )}

        {orderDetails.order_details?.shipping_address && (
          <div className="shipping-details">
            <h2>Shipping Address</h2>
            <p>{orderDetails.order_details.shipping_address}</p>
          </div>
        )}

        <div className="actions">
          <button onClick={() => window.location.href = "/"} className="action-button">
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
