import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useAuth as useOidcAuth } from "react-oidc-context";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const oidcAuth = useOidcAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (oidcAuth.isAuthenticated && oidcAuth.user?.profile) {
      console.log("OIDC User authenticated:", oidcAuth.user);
      const { profile, access_token } = oidcAuth.user;
      const groups = profile["cognito:groups"] || [];
      console.log("Fetched groups in AuthProvider:", groups);

      setUser({ ...profile, groups });
      setAccessToken(access_token);
      setIsAuthenticated(true);

      try {
        localStorage.setItem("authToken", access_token);
      } catch (err) {
        console.error("Failed to save AuthToken to localStorage:", err);
      }
    } else {
      console.warn("User is not authenticated or profile is missing");
      setIsAuthenticated(false);
      setAccessToken(null);
      setUser(null);
    }
    setLoading(false);
  }, [oidcAuth.isAuthenticated, oidcAuth.user]);

  const signinRedirect = useCallback(() => {
    console.log("Redirecting to Cognito hosted form...");
    oidcAuth.signinRedirect();
  }, [oidcAuth]);

  const logout = useCallback(() => {
    oidcAuth
      .removeUser()
      .then(() => {
        console.log("OIDC user session cleared.");
        setUser(null);
        setIsAuthenticated(false);
        setAccessToken(null);
        try {
          localStorage.removeItem("authToken");
        } catch (err) {
          console.error("Failed to remove AuthToken from localStorage:", err);
        }
      })
      .catch((err) => {
        console.error("Failed to remove OIDC user session:", err);
      });
  }, [oidcAuth]);

  const hasRole = useCallback(
    (roles) => {
      // Support single role (string) or multiple roles (array)
      const requiredRoles = Array.isArray(roles) ? roles : [roles];
      const userGroups = user?.groups || [];
      const hasAnyRole = requiredRoles.some((role) => userGroups.includes(role));
      console.log(`Checking if user has roles '${requiredRoles}':`, hasAnyRole);
      return hasAnyRole;
    },
    [user]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        loading,
        logout,
        hasRole,
        signinRedirect,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export { AuthContext };
