import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to get the token from localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const token = getAuthToken(); // Fetch token
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error during request to ${endpoint}:`, error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    }
    throw error; // Rethrow for handling in the caller
  }
};

/**
 * Add or Update Product with images
 * Skips appending product_id if it’s null, undefined, or "undefined"
 */
export const addOrUpdateProduct = async (
  productData,
  files,
  primaryImageIndex = 0,
  imageOrder = {}
) => {
  const formData = new FormData();

  // Append product data, excluding 'attributes'
  for (const key in productData) {
    if (key === 'attributes') {
      // We'll handle attributes after this loop
      continue;
    }

    // If this is the product_id and it's invalid, skip appending it
    if (
      key === 'product_id' &&
      (
        productData[key] === undefined ||
        productData[key] === null ||
        productData[key] === 'undefined'
      )
    ) {
      console.log("Skipping product_id because it's invalid:", productData[key]);
      continue;
    }

    // Otherwise, append the field
    const value =
      typeof productData[key] === 'object'
        ? JSON.stringify(productData[key])
        : productData[key];

    formData.append(key, value);
  }

  // Append 'attributes' as JSON string if present
  if (productData.attributes) {
    formData.append('attributes', JSON.stringify(productData.attributes));
  }

  // Append files, marking primary image and order
  if (files && files.length > 0) {
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
      formData.append(`file_order_${index}`, imageOrder[index] || index);
      if (index === primaryImageIndex) {
        formData.append('primary_image_index', index);
      }
    });
  }

  // Finally, make the request
  return await makeRequest('post', '/admin/add-or-update-product', formData);
};

// Fetch all price dynamics data
export const fetchPriceDynamicsData = async () => {
  return await makeRequest('get', '/products/price-dynamics');
};

// Update product price in price dynamics
export const updateDynamicPricing = async (productId, priceData) => {
  return await makeRequest('post', '/admin/update-price', {
    ...priceData,
    product_id: productId,
  });
};

// Fetch detailed pricing information for a specific product
export const fetchProductPricingDetails = async (productId) => {
  return await makeRequest('get', `/admin/dynamic-pricing/${productId}`);
};

// Fetch product price history
export const fetchProductPriceHistory = async (productId) => {
  return await makeRequest('get', `/admin/price-history/${productId}`);
};
