import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Spin,
  notification,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { fetchProductTypes, fetchProductTypeSchema } from '../../services/productService';
import './ProductModal.css';

const ProductModal = ({
  visible,
  onOk,
  onCancel,
  initialValues,
  isEditing,
  categoryOptions = [],
  loading,
  onDeleteImage,
}) => {
  const [form] = Form.useForm();
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productTypeSchemas, setProductTypeSchemas] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [primaryImageIndex, setPrimaryImageIndex] = useState(null);

  useEffect(() => {
    const loadProductData = async () => {
      try {
        const [types, schemas] = await Promise.all([
          fetchProductTypes(),
          fetchProductTypeSchema(),
        ]);
        setProductTypeOptions(types.map((type) => ({ value: type.id, label: type.name })));
        setProductTypeSchemas(schemas);
      } catch (error) {
        console.error('Error fetching product types or schemas:', error);
      }
    };
    loadProductData();
  }, []);

  useEffect(() => {
    if (visible && initialValues && !loading) {
      setFormValuesAndFiles(initialValues);
    } else if (!visible) {
      resetForm();
    }
  }, [visible, initialValues, loading]);

  const setFormValuesAndFiles = (values) => {
    const productType = productTypeOptions.find((type) => type.label === values.product_type);
    const productTypeId = productType ? productType.value : null;
    setSelectedProductType(productTypeId);

    const initialFormValues = { ...values, product_type_id: productTypeId };

    // Pre-fill dynamic attributes
    if (values.attributes) {
      Object.entries(values.attributes).forEach(([key, value]) => {
        initialFormValues[key] = value;
      });
    }

    // Handle categories
    if (values.categories && values.categories.length > 0) {
      initialFormValues.categories = values.categories.map((cat) => cat.id);
    }

    form.setFieldsValue(initialFormValues);

    // Handle assets
    if (values.assets) {
      const initialFiles = values.assets.map((asset, index) => ({
        uid: asset.id,
        name: asset.image_path.split('/').pop(),
        status: 'done',
        url: asset.image_path,
        isPrimary: asset.is_primary,
        index,
      }));
      setFileList(initialFiles);
      const primaryIndex = initialFiles.findIndex((file) => file.isPrimary);
      setPrimaryImageIndex(primaryIndex !== -1 ? primaryIndex : null);
    }

    // Set schema based on product type
    const schema = productTypeSchemas.find((s) => s.id === productTypeId);
    setSelectedSchema(schema || null);
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedProductType(null);
    setSelectedSchema(null);
    setFileList([]);
    setPrimaryImageIndex(null);
  };

  const handleImageUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleOk = async () => {
  try {
    const values = await form.validateFields();

    // Prepare dynamic attributes
    const dynamicAttributes = {};
    if (selectedSchema && Array.isArray(selectedSchema.attributes)) {
      selectedSchema.attributes.forEach((attr) => {
        dynamicAttributes[attr.attribute_name] = values[attr.attribute_name];
        delete values[attr.attribute_name];
      });
    }
    values.attributes = dynamicAttributes;

    // Prepare files and image metadata
    const files = fileList.filter((file) => !file.url).map((file) => file.originFileObj);
    const imageOrder = fileList.map((file, index) => ({ id: file.uid, order: index }));
    const primaryImage = fileList[primaryImageIndex];

    // Call onOk with the values
    await onOk({ ...values, imageOrder, primaryImage }, files);

    notification.success({ message: 'Product saved successfully!' });
    onCancel(); // Close modal on success
  } catch (error) {
    console.error('Error submitting form:', error);
    notification.error({ message: error.message || 'Failed to save product.' });
  }
};

  return (
    <Modal
      visible={visible}
      title={isEditing ? 'Edit Product' : 'Add Product'}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Save"
      cancelText="Cancel"
    >
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <Form form={form} layout="vertical" name="productForm">
          <Form.Item name="name" label="Product Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ minRows: 2 }} />
          </Form.Item>
          <Form.Item name="price_tracking_url" label="Price Tracking URL">
            <Input />
          </Form.Item>
          <Form.Item name="categories" label="Categories" rules={[{ required: true }]}>
            <Select options={categoryOptions} mode="multiple" />
          </Form.Item>
          <Form.Item name="is_active" label="Active Status">
            <Select
              options={[
                { value: true, label: 'Active' },
                { value: false, label: 'Inactive' },
              ]}
            />
          </Form.Item>
          <Form.Item name="product_type_id" label="Product Type" rules={[{ required: true }]}>
            <Select
              options={productTypeOptions}
              onChange={(value) => {
                setSelectedProductType(value);
                const schema = productTypeSchemas.find((s) => s.id === value);
                setSelectedSchema(schema || null);
              }}
            />
          </Form.Item>
          {selectedSchema &&
            selectedSchema.attributes?.map((attr) => (
              <Form.Item
                key={attr.attribute_name}
                name={attr.attribute_name}
                label={attr.label || attr.attribute_name}
              >
                <Input />
              </Form.Item>
            ))}
          <Form.Item label="Product Images">
            <Upload
              accept="image/*"
              multiple
              fileList={fileList}
              onChange={handleImageUpload}
              beforeUpload={() => false}
              listType="picture-card"
            >
              {fileList.length < 8 && <UploadOutlined />}
            </Upload>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

ProductModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isEditing: PropTypes.bool,
  categoryOptions: PropTypes.array,
  loading: PropTypes.bool,
  onDeleteImage: PropTypes.func,
};

export default ProductModal;
