import React from "react";
import { useCart } from "../context/CartContext";
import Header from "../components/layout/header/Header";
import { useNavigate } from "react-router-dom";
import "./CartPage.css";

const CartPage = () => {
  const { cart, dispatch } = useCart();
  const { items, totalCost, totalItems } = cart;
  const navigate = useNavigate();

  const handleRemoveItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: { id } });
  };

  const handleQuantityChange = (id, quantity) => {
    if (quantity < 1) return;
    dispatch({
      type: "UPDATE_QUANTITY",
      payload: { id, quantity: parseInt(quantity, 10) },
    });
  };

  const handleProceedToCheckout = () => {
    // Navigate to the checkout page
    navigate("/checkout");
  };

  return (
    <>
      <Header />
      <div className="cart-page">
        <h1>Your Cart</h1>
        {totalItems === 0 ? (
          <div className="cart-empty">
            <img
              src="/assets/images/cart-empty.svg"
              alt="Empty Cart"
              className="cart-empty-image"
            />
            <p>Your cart is empty.</p>
            <a href="/" className="btn btn-primary">
              Continue Shopping
            </a>
          </div>
        ) : (
          <>
            <div className="cart-items">
              {items.map((item) => (
                <div key={item.id} className="cart-item">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="cart-item-image"
                  />
                  <div className="cart-item-details">
                    <h3>{item.name}</h3>
                    <p>${item.price}</p>
                    <div className="cart-item-quantity">
                      <label>Quantity:</label>
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(item.id, e.target.value)
                        }
                      />
                    </div>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleRemoveItem(item.id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="cart-summary">
              <h2>Cart Summary</h2>
              <p>Total Items: {totalItems}</p>
              <p>Total Cost: ${totalCost.toFixed(2)}</p>
              <button
                className="btn btn-success"
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CartPage;
