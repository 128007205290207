import React, { useState } from "react";
import "./AddAddressModal.css";

const AddAddressModal = ({ isOpen, onClose, onSave }) => {
  const [address, setAddress] = useState({
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(address);
    setAddress({
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Add New Address</h3>
        <form>
          <input
            type="text"
            name="address_line1"
            placeholder="Address Line 1"
            value={address.address_line1}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="address_line2"
            placeholder="Address Line 2"
            value={address.address_line2}
            onChange={handleChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={address.city}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={address.state}
            onChange={handleChange}
          />
          <input
            type="text"
            name="postal_code"
            placeholder="Postal Code"
            value={address.postal_code}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="country"
            placeholder="Country"
            value={address.country}
            onChange={handleChange}
            required
          />
        </form>
        <div className="modal-actions">
          <button onClick={handleSave} className="modal-button">
            Save Address
          </button>
          <button onClick={onClose} className="modal-button cancel">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModal;
