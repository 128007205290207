import React from "react";
import { useNavigate } from "react-router-dom";
import "./DashboardCard.css";

const DashboardCard = ({ title, description, link, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick(); // Execute custom onClick handler if provided
    } else if (link) {
      navigate(link); // Fallback to navigation if no onClick is provided
    }
  };

  return (
    <div className="dashboard-card" onClick={handleClick}>
      <div className="dashboard-card-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default DashboardCard;
