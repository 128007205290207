import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Utility to get the token from localStorage
const getAuthToken = () => {
  const token = localStorage.getItem('authToken'); // Updated key to `authToken`
  if (token) {
    console.log("[INFO] Successfully fetched auth token from localStorage.");
  } else {
    console.warn("[WARN] No auth token found in localStorage.");
  }
  return token;
};

// Generic Axios request wrapper
const makeRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const token = getAuthToken(); // Fetch token
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    console.log(`[INFO] Making ${method.toUpperCase()} request to ${endpoint}`);
    const response = await axios({
      method,
      url: `${BASE_URL}${endpoint}`,
      headers,
      data,
      params,
    });

    console.log(`[INFO] Response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`[ERROR] Error during request to ${endpoint}:`, error);

    if (error.response) {
      console.error("[DEBUG] Server responded with:", error.response.data);

      // Handle 401 Unauthorized errors
      if (error.response.status === 401) {
        console.warn("[WARN] Unauthorized: Token might be invalid or expired.");
        localStorage.removeItem('authToken'); // Remove invalid token
        throw new Error("Unauthorized access. Please log in again.");
      }
    }

    throw error; // Re-throw error for handling in the caller
  }
};

// Service Methods

/**
 * Fetches a PaymentIntent client secret from the server.
 * @returns {Promise} Axios response promise.
 */
export const fetchClientSecret = async () => {
  console.log("[INFO] Fetching PaymentIntent client secret.");
  return await makeRequest('post', '/payment/create-payment-intent');
};

/**
 * Creates a Stripe Checkout Session.
 * Accepts an optional payload to include metadata such as `user_address_id`.
 * @param {Object} payload - Additional data to pass to the backend, e.g., { user_address_id: 'xyz' }
 * @returns {Promise} Axios response promise.
 */
export const createCheckoutSession = async (payload = {}) => {
  console.log("[INFO] Creating Stripe Checkout session with payload:", payload);
  return await makeRequest('post', '/payment/create-checkout-session', payload);
};

// Export all services
export default {
  fetchClientSecret,
  createCheckoutSession,
};
