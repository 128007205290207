import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "./welcomePage.css";

const WelcomePage = () => {
  const { user, hasRole } = useAuth(); // Include hasRole for group-based authorization
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    console.log(`Navigating to: ${path}`);
    navigate(path);
  };

  return (
    <div className="welcome-container">
      <h1 className="welcome-title">Welcome, {user?.name || "User"}!</h1>
      <div className="card-container">
        {/* Card for Home */}
        <div className="card" onClick={() => handleNavigation("/")}>
          <h3>Home</h3>
          <p>Return to the home page.</p>
        </div>

        {/* Conditionally Render Admin Dashboard Card */}
        {hasRole("admin") && (
          <div className="card" onClick={() => handleNavigation("/dashboard")}>
            <h3>Admin Dashboard</h3>
            <p>Manage products, prices, and more.</p>
          </div>
        )}

        {/* Conditionally Render Account Page Card */}
        {(hasRole("user") || hasRole("admin")) && (
          <div className="card" onClick={() => handleNavigation("/account")}>
            <h3>Account</h3>
            <p>View and manage your account details.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
